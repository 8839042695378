.layout {
  position: relative;
  z-index: 5;
  display: block;
  flex: 1;
}
.layoutbox {
  flex: 1;
  background-color: #202938;
}
