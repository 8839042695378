@import './variables';
@import './mixins';
@import './component/login';
@import './component/header';
@import './component/notifications';
@import './component/userbox';
@import './component/layout';
@import './component/suspenseloader';
@import './component/sidebar';
@import './component/app';
@import './component/table';
@import 'fonts';

//common classes goes here
.cursorPointer {
  cursor: pointer;
}

.cursorDefault {
  cursor: default !important;
}

.hr-line {
  border: 1px solid $borderColor;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.p-fixed {
  position: fixed;
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

//multiple file upload icon size

.text-center {
  text-align: center;
}
.text-start {
  text-align: start;
}

//Text Transform
.textUppercase {
  text-transform: uppercase !important;
}

.textCapitalize {
  text-transform: capitalize !important;
}
.textNone {
  text-transform: none !important;
}

//text align
.flex-basic-center {
  @include flex-basic-center;
}

.flex-column-center {
  @include flex-column-center;
}

.flex-basic-start {
  justify-content: flex-start !important;
  @include flex-basic-center;
}

.flex-basic-space-between {
  justify-content: space-between !important;
  @include flex-basic-center;
}

.flex-basic-space-around {
  justify-content: space-around !important;
  @include flex-basic-center;
}

.flex-basic-space-evenly {
  justify-content: space-evenly !important;
  @include flex-basic-center;
}

.flex-basic-end {
  justify-content: flex-end !important;
  @include flex-basic-center;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-column-end {
  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
}

.flex-column-start {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  justify-content: flex-start;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.textDecorationNone {
  text-decoration: none !important;
}
.textweight {
  @include font-weight-bold;
}
.textalign {
  text-align: center;
}

//height
.h-100 {
  height: 100%;
}
.h-300 {
  height: 300;
}
.h-320 {
  height: 320px;
}

.h-250 {
  height: 250px;
}

.min-height-56p {
  min-height: 56px;
}

//width per clasess

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.w-200 {
  width: 200px;
}

.w-80p {
  width: 80px;
}

.w-100p {
  width: 100px;
}

.w-500p {
  width: 500px;
}

.d-flex {
  display: flex;
}

.m-width {
  min-width: 350 !important ;
}

.sidebarwrapper {
  position: fixed;
  left: 0;
  top: 0;
}

.baselayout {
  flex: 1;
  height: 100%;
}

.selectformcontrol {
  width: 100% !important;
}

.overflow_auto {
  overflow: auto;
}
.overflow_visible {
  overflow: visible;
}
.overflowY {
  overflow-y: auto;
}
.flex_1 {
  flex: 1;
}
.flex_2 {
  flex: 2;
}
.overflow_x_hidden {
  overflow-x: hidden;
}
.alignitem {
  align-items: center;
}

.empty-column {
  background-color: white !important;
}

.simpledialogbox {
  height: 90vh !important;
  width: 50vw !important;
}
.setpasswordbox {
  width: 50vh !important;
}
//////////////////////
/// padding
.P-1 {
  padding: 1;
}

.loader {
  @include flex-basic-center;
  padding: 5%;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.my-data-grid-container {
  .MuiDataGrid-root {
    & .MuiDataGrid-columnHeaderTitle {
      font-weight: 700;
      font-size: 13px;
    }
    & .MuiDataGrid-cell:focus-within {
      outline: none !important;
    }
  }
}

.my-data-grid-containers {
  .MuiDataGrid-root {
    & .MuiDataGrid-columnHeaderTitle {
      font-weight: 700;
      font-size: 13px;
    }
    & .MuiDataGrid-cell:focus-within {
      outline: none !important;
    }
    & .MuiDataGrid-columnHeaderCheckbox {
      .MuiDataGrid-columnHeaderTitleContainer {
        display: none;
      }
    }
  }
}

.signbtn {
  .MuiButtonBase-root {
    .MuiButton-root {
      &.Mui-disabled {
        color: rgba(34, 51, 84, 0.5);
        box-shadow: none;
        background-color: rgba(34, 51, 84, 0.22);
      }
    }
  }
}

.tabcss {
  font-weight: 400;
  size: 14px;
}

.labelPurpleColor {
  background-color: #7c29ec;
}

.donutChartCardWidth {
  height: 280px;
}

.orgcancalbtn {
  width: 50px;
  height: 18px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #121433;
  opacity: 0.78;
}

.closeIcon {
  color: #121433;
}

.timeline-connector-dashed-border {
  background-image: linear-gradient(
    to bottom,
    #8b939f 40%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: left;
  background-size: 1px 15px;
  background-repeat: repeat-y;
}

.timeline-connector-solid-border {
  background-color: #8b939f;
}

.custom-filter-chip {
  &.MuiChip-root {
    border: 1px solid white;
    border-radius: 7px;
    color: white;
    font-weight: 500;
    & .MuiChip-label {
      margin-right: 4px;
    }
  }
}

.text-error {
  color: $error !important;
}

.text-grey {
  color: $borderColor;
}

.card-background {
  border-radius: 20px !important;
  border: 0.5px solid white !important;
  background: linear-gradient(
    252.01deg,
    rgba(40, 113, 230, 0) 11.05%,
    #1a2732 74.31%
  ) !important;
}

.rules-table-background {
  background: $cardBackground;
  border-radius: 16px;
}

.custom-card {
  &.MuiPaper-root {
    background-color: transparent !important;
  }
}

.custom-filter-button {
  &.MuiButtonBase-root {
    border: 1px solid white;
    border-radius: 4px !important;
    color: white;
    &:hover {
      border: 1px solid white;
      color: white;
    }
  }
}

.custom-action-button {
  &.MuiButtonBase-root {
    color: $primaryColor;
    background-color: white;
    &:hover {
      color: $primaryColor;
      background-color: white;
    }
  }
}

.custom-filter-textfield {
  &.MuiFormControl-root {
    margin-top: 8px;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.stack-style {
  & .MuiDivider-root {
    margin: 0px !important;
    margin-left: 0px !important;
  }
}

.profile-container {
  border-radius: 10px;
  background: $cardBackground;
  .profile-avatar {
    background-color: $primaryColor;
    width: 50px;
    height: 50px;
    border: 3px solid white;
  }
}

.text-xxl {
  font-size: 40px !important;
  line-height: 54px !important;
  @include font-weight-regular;
}

.text-h1 {
  font-size: 32px !important;
  line-height: 54px !important;
  @include font-weight-semibold;
}

.text-h1-regular {
  font-size: 32px !important;
  line-height: 54px !important;
  @include font-weight-regular;
}

.text-h2 {
  font-size: 24px !important;
  line-height: 36px !important;
  @include font-weight-semibold;
}

.text-header {
  font-size: 20px !important;
  line-height: 24px !important;
  @include font-weight-semibold;
}

.text-h3 {
  font-size: 18px !important;
  line-height: 28px !important;
  @include font-weight-semibold;
}

.text-h3-regular {
  font-size: 18px !important;
  line-height: 28px !important;
  @include font-weight-regular;
}

.text-large-bold {
  font-size: 16px !important;
  line-height: 24px !important;
  @include font-weight-bold;
}

.text-large-semibold {
  font-size: 16px !important;
  line-height: 24px !important;
  @include font-weight-semibold;
}

.text-large-medium {
  font-size: 16px !important;
  line-height: 24px !important;
  @include font-weight-medium;
}

.text-large-regular {
  font-size: 16px !important;
  line-height: 24px !important;
  @include font-weight-regular;
}

.text-medium-bold {
  font-size: 14px !important;
  line-height: 20px !important;
  @include font-weight-bold;
}

.text-medium-semibold {
  font-size: 14px !important;
  line-height: 20px !important;
  @include font-weight-semibold;
}

.text-medium-regular {
  font-size: 14px !important;
  line-height: 20px !important;
  @include font-weight-regular;
}

.text-small-bold {
  font-size: 12px !important;
  line-height: 18px !important;
  @include font-weight-bold;
}

.text-small-semibold {
  font-size: 12px !important;
  line-height: 18px !important;
  @include font-weight-semibold;
}

.text-small-medium {
  font-size: 12px !important;
  line-height: 18px !important;
  @include font-weight-medium;
}

.text-small-regular {
  font-size: 12px !important;
  line-height: 18px !important;
  @include font-weight-regular;
}

.text-extra-small-regular {
  font-size: 10px !important;
  line-height: 16px !important;
  @include font-weight-regular;
}
.text-gradient-color {
  position: absolute;
  font-size: 28px !important;
  line-height: 28px !important;
  font-family: inherit;
  font-weight: 700px;
  background: rgb(163, 230, 29);
  background: linear-gradient(
    90deg,
    rgba(163, 230, 29, 1) 0%,
    rgba(27, 118, 200, 1) 50%,
    rgba(255, 82, 226, 1) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.toggle-button-style {
  &.MuiToggleButtonGroup-root {
    border-radius: 0 !important;
    & .MuiToggleButton-root {
      @extend .text-medium-regular;
      &:first-child {
        border-radius: 10px 0px 0px 10px !important;
      }
      &:nth-child(2) {
        border-radius: 0px 0px 0px 0px !important;
      }
      &:last-child {
        border-radius: 0px 10px 10px 0px !important;
      }
      border-radius: 10px 0px 0px 10px !important;
    }
  }
}

.primary-background {
  background-color: $primaryColor !important;
}

.alert-card {
  border-radius: 10px;
  background: $cardBackground;
}

.default-background {
  background: #202938 !important;
}

.catalogue-list {
  border-radius: 4px;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: $primaryColor !important;
}

.border-radius-16 {
  @include border-radius(16px);
}

.border-radius-8 {
  @include border-radius(8px);
}

.priority-border-high {
  border-left: 10px solid #ffa31a;
}

.priority-border-low {
  border-left: 10px solid #58c923;
}

.priority-border-medium {
  border-left: 10px solid #dc5f00;
}

.priority-border-critical {
  border-left: 10px solid #ff0000;
}

.priority-high {
  color: #ffa31a !important;
  border-color: #ffa31a !important;
}

.priority-low {
  color: #58c923 !important;
  border-color: #58c923 !important;
}

.priority-medium {
  color: #dc5f00 !important;
  border-color: #dc5f00 !important;
}

.priority-critical {
  color: #ff0000 !important;
  border-color: #ff0000 !important;
}

.border-right {
  &.MuiGrid-container {
    .MuiGrid-item {
      position: relative;
      &::after {
        position: absolute;
        content: '';
        height: 30px;
        width: 1px;
        background-color: #ffffff;
      }
      &:first-child {
        &::after {
          right: -6px;
          top: 18px;
        }
      }
      &:nth-child(2) {
        &::after {
          right: -6px;
          top: 18px;
        }
      }
      &:nth-child(3) {
        &::after {
          right: 0px;
          top: 18px;
        }
      }
      &:nth-child(4) {
        &::after {
          right: 0px;
          top: 18px;
        }
      }
      &:last-child {
        padding-left: 0px;
        &::after {
          content: none;
        }
      }
    }
  }
}

#chartContainer .apexcharts-tooltip {
  color: #000000;
}

#chartContainer .apexcharts-tooltip .apexcharts-tooltip-series-group.active {
  background: #ffffff !important;
}

.chat-window-scroll {
  max-height: 300px;
  overflow: auto;
  @include commonScrollBar;
}

.alert-report-scroll {
  max-height: 300px;
  min-height: 300px;
  overflow: auto;
  @include commonScrollBar;
}

.chat-message {
  & .chat-item {
    background: $primaryColor;
    border-radius: 8px;
    font-size: 13px;
    line-height: 1.2;
    padding: 8px;
    max-width: 65%;
    position: relative;
    z-index: 200;
    word-wrap: break-word;

    &::after {
      content: '';
      display: block;
      width: 0px;
      height: 0px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 12px solid $primaryColor;
      position: absolute;
      bottom: -7px;
      left: -5px;
      transform: rotate(45deg);
      z-index: 100;
    }
  }
}

.integration-card-background {
  color: #14181f;
}

.integration-card-description {
  color: #8b939f;
}

.integration-card-height {
  min-height: 240px;
  height: 240px;
}

.text-green {
  color: #58c923;
}

.text-ellipsis-1 {
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-ellipsis-3 {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.black-background {
  background: #1e1f24;
}

.plane-card {
  background: #1e1f24;
  border-radius: 8px;
  border: 1px solid white;
}

.alert-info-border-right {
  &.MuiGrid-container {
    .MuiGrid-item {
      position: relative;
      &::after {
        position: absolute;
        content: '';
        height: 195px;
        width: 1px;
        background-color: #ffffff;
      }
      &:first-child {
        &::after {
          right: -6px;
          top: 16px;
        }
      }
      &:nth-child(2) {
        &::after {
          right: -6px;
          top: 16px;
        }
      }
      &:nth-child(3) {
        &::after {
          right: 0px;
          top: 16px;
        }
      }
      &:nth-child(4) {
        &::after {
          right: 0px;
          top: 16px;
        }
      }
      &:last-child {
        padding-left: 0px;
        &::after {
          content: none;
        }
      }
    }
  }
}

.remediate-alert-info-border-right {
  &.MuiGrid-container {
    .MuiGrid-item {
      position: relative;
      &::after {
        position: absolute;
        content: '';
        height: 95px;
        width: 1px;
        background-color: #ffffff;
      }
      &:first-child {
        &::after {
          right: -6px;
          top: 18px;
        }
      }
      &:nth-child(2) {
        &::after {
          right: -6px;
          top: 18px;
        }
      }
      &:nth-child(3) {
        &::after {
          right: 0px;
          top: 18px;
        }
      }
      &:nth-child(4) {
        &::after {
          right: 0px;
          top: 18px;
        }
      }
      &:last-child {
        padding-left: 0px;
        &::after {
          content: none;
        }
      }
    }
  }
}

.no-border {
  &.MuiGrid-container {
    .MuiGrid-item {
      position: relative;
      &::after {
        content: none;
      }
    }
  }
}

.role-chain-scroll {
  max-height: 305px;
  overflow: auto;
  @include commonScrollBar;
}

.plane-section {
  height: calc(100vh - 320px);
  overflow: auto;
  @include commonScrollBar;
}

.plane-box {
  border: 1px solid white;
  border-radius: 8px;
  background: #1e1f24;
  max-height: 200px;
  overflow: auto;
  @include commonScrollBar;
}

.plane-icon {
  border-radius: 50%;
  background: #202938 !important;
}

.plane-sidebar {
  position: absolute;
  right: -24px;
  top: 0;
  .plane-button {
    border-radius: 50%;
    background: white;
  }
}

.plane-sidebar-close-view {
  position: relative;
  background: #202938 !important;
  border-radius: 8px;
  width: 20px;
  min-height: 500px;
  .plane-sidebar-closed {
    position: absolute;
    left: 0px;
    top: 16px;
    .plane-button {
      border-radius: 50%;
      background: white;
    }
  }
}

.no-data-height {
  height: calc(100vh - 350px);
}

.select-filters-height {
  height: calc(100vh - 200px);
}

.select-filters-height-if {
  height: calc(100vh - 180px);
}

.alert-detail-toggle {
  position: absolute;
  right: 12px;
  top: 80px;
}

.plane-collection-sticky-section {
  position: sticky;
  top: 32px;
  z-index: 4;
  background: #1e1f24;
}

.if-plane-collection-sticky-section {
  position: sticky;
  top: 94px;
  z-index: 4;
  background: #1e1f24;
}

.investigation-plane-collection-sticky-section {
  position: sticky;
  top: 100px;
  z-index: 4;
  background: #1e1f24;
}

.risk-score-scroll {
  overflow-x: auto;
  @include commonScrollBar;
}

.risk-score-card-border {
  &.MuiGrid-container {
    .MuiGrid-item {
      position: relative;
      &::after {
        position: absolute;
        content: '';
        height: 44px;
        width: 1px;
        background-color: #ffffff;
      }
      &::after {
        right: 0px;
        top: 14px;
      }
      &:nth-child(6) {
        padding-left: 0px;
        &::after {
          content: none;
        }
      }
      &:last-child {
        padding-left: 0px;
        &::after {
          content: none;
        }
      }
    }
  }
}

.rule-type-color {
  color: white !important;
  background-color: #7e57c2 !important;
}

.alert-critical {
  color: white !important;
  background-color: #ff0000 !important;
}

.alert-high {
  color: white !important;
  background-color: #ef6c00 !important;
}

.alert-medium {
  color: white !important;
  background-color: #ffc400 !important;
}

.alert-low {
  color: white !important;
  background-color: #8bc34a !important;
}

.text-label-color {
  color: #6a9ceb !important;
}

.text-sub-label-color {
  color: #d2d2d2 !important;
}

.change-log-scroll {
  max-height: 800px;
  overflow: auto;
  @include commonScrollBar;
}

.action-log-scroll {
  max-height: 450px;
  overflow: auto;
  @include commonScrollBar;
}

.action-number-chip {
  border-radius: 50%;
  min-height: 30px;
  min-width: 30px;
  background-color: $primaryColor !important;
}

.dropdown-style {
  & .MuiSelect-select {
    padding: 3.5px 32px 5.5px 8px !important;
  }
}

.white-border {
  &.MuiDivider-root {
    border-color: #ffffff !important;
    background: #ffffff !important;
  }
}

.priority-high-label {
  color: #ffa31a !important;
}

.priority-low-label {
  color: #58c923 !important;
}

.priority-medium-label {
  color: #dc5f00 !important;
}

.priority-critical-label {
  color: #ff0000 !important;
}

.priority-high-border {
  border-top: 8px solid #ffa31a !important;
}

.priority-low-border {
  border-top: 8px solid #58c923 !important;
}

.priority-medium-border {
  border-top: 8px solid #dc5f00 !important;
}

.priority-critical-border {
  border-top: 8px solid #ff0000 !important;
}

.air-report-style {
  background: rgb(106, 156, 235);
  background: linear-gradient(
    90deg,
    rgba(106, 156, 235, 1) 0%,
    rgba(198, 37, 255, 1) 50%,
    rgba(255, 146, 229, 1) 100%
  );
  position: absolute;
  font-size: 28px !important;
  line-height: 28px !important;
  font-family: inherit;
  font-weight: 700;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.light-default-background {
  background: #2f3f5a !important;
}

.alert-priority-bar {
  &:before {
    top: -6px;
    left: -14px;
    height: 60px;
    width: 7px;
    content: '';
    border-radius: 12px;
    background-color: #ff0000 !important;
    position: absolute;
  }
}

.priority-high-alert {
  &:before {
    background-color: #ffa31a !important;
  }
}

.priority-low-alert {
  &:before {
    background-color: #58c923 !important;
  }
}

.priority-medium-alert {
  &:before {
    background-color: #dc5f00 !important;
  }
}

.priority-critical-alert {
  &:before {
    background-color: #ff0000 !important;
  }
}

.phoneNumberInput {
  & .MuiInputBase-root {
    & .MuiInputBase-input {
      padding: 12px !important;
    }
  }
}

.phoneNumberError {
  & .MuiInputBase-root {
    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid red !important;
    }
  }
}
