@font-face {
  font-family: 'Circular Std Black';
  src: url('../assets/font/Circular\ Std\ Bold.ttf') format('TrueType');
  //  font-display: block;
  //  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circular Std Book';
  src: url('../assets/font/Circular\ Std\ Book.ttf') format('TrueType');
  //  font-display: block;
  //  font-weight: normal;
  font-style: normal;
}
